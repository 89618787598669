import {
  GET_ALL_SALES_REQUESTS,
  GET_ALL_SALES_REQUESTS_SUCCESS,
  GET_ALL_SALES_REQUESTS_FAILURE,
} from "./actionTypes";

const initialState = {
  sales: [],
  isLoggedIn: null,
  loading: false,
  error: "",
};

const sales = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SALES_REQUESTS:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_SALES_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        sales: action.payload,
      };

    case GET_ALL_SALES_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    default:
      return state;
  }
};

export default sales;
