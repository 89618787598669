const { default: server } = require("./server");

export const getCategoriesApi = async () => {
  const response = await server().get(`/BlogCategory/GetAllBlogCategory`);
  return response.data;
};

export const getSingleCategoryApi = async ({ id }) => {
  const response = await server().get(`/BlogCategory/BlogCategoryId/${id}`);
  return response.data;
};

export const addCategoryApi = async ({ data }) => {
  const response = await server().post(`/BlogCategory/AddBlogCategory`, data);
  return response.data;
};

export const updateCategoryApi = async ({ data }) => {
  const response = await server().put(`/BlogCategory/EditBlogCategory`, data);
  return response.data;
};

export const deleteCategoryApi = async ({ id }) => {
  const response = await server().delete(
    `/BlogCategory/DeleteBlogCategory/${id}`
  );
  return response.data;
};
