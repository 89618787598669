import {
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_SUCCESS,
  GET_ALL_SUBSCRIBERS_FAILURE,
} from "./actionTypes";

const initialState = {
  subscribers: [],
  isLoggedIn: null,
  loading: false,
  error: "",
};

const subscribers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIBERS:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscribers: action.payload,
      };

    case GET_ALL_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    default:
      return state;
  }
};

export default subscribers;
