import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILURE,
  ADD_PERMISSION,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILURE,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAILURE,
  DELETE_PERMISSION,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
} from "./actionTypes";

const initialState = {
  permissions: [],
  permission: {},
  metadata: {},
  isLoggedIn: null,
  loading: false,
  error: "",
};

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: action.payload?.items,
        metadata: {
          count: action.payload?.count,
          pageSize: action.payload?.pageSize,
          pageIndex: action.payload?.pageIndex,
        },
      };

    case GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case GET_PERMISSION:
      return {
        ...state,
        loading: true,
      };

    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permission: action.payload,
      };

    case GET_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case ADD_PERMISSION:
      return {
        ...state,
        loading: true,
      };

    case ADD_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: [...state.permissions, action.payload],
      };

    case ADD_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case UPDATE_PERMISSION:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: state.permissions.map((permission) =>
          permission.id === action.payload.id ? action.payload : permission
        ),
      };

    case UPDATE_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case DELETE_PERMISSION:
      return {
        ...state,
        loading: true,
      };

    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: state.permissions.filter(
          (permission) => permission?.id !== action.payload?.id
        ),
      };

    case DELETE_PERMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    default:
      return state;
  }
};

export default permissions;
