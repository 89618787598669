import {
  GET_ALL_SALES_REQUESTS,
  GET_ALL_SALES_REQUESTS_FAILURE,
  GET_ALL_SALES_REQUESTS_SUCCESS,
} from "./actionTypes";

export const getSalesRequests = (payload) => {
  return {
    type: GET_ALL_SALES_REQUESTS,
    payload,
  };
};

export const getSalesRequestsSuccess = (payload) => {
  return {
    type: GET_ALL_SALES_REQUESTS_SUCCESS,
    payload,
  };
};

export const getSalesRequestsFailure = (payload) => {
  return {
    type: GET_ALL_SALES_REQUESTS_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================
