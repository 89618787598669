import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPermission, updatePermission } from "store/actions";
import toast from "react-hot-toast";

const EditAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { permission } = useSelector((state) => state.permissions);

  useEffect(() => {
    dispatch(getPermission({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: permission?.name,
    });
  }, [permission, reset]);

  const onSubmit = (data) => {
    data.cmsPermissionId = id;

    dispatch(
      updatePermission({
        data,
        navigate,
        toast,
      })
    );
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل البيانات</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>اسم الصلاحية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم الصلاحية"
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" &&
                          "يرجي ادخال اسم الصلاحية"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
