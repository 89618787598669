import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  addRoleFailure,
  addRoleSuccess,
  deleteRoleFailure,
  deleteRoleSuccess,
  getRoleFailure,
  getRoleSuccess,
  getRolesFailure,
  getRolesSuccess,
  updateRoleFailure,
  updateRoleSuccess,
} from "./actions";
import {
  ADD_ROLE,
  DELETE_ROLE,
  GET_ROLE,
  GET_ROLES,
  UPDATE_ROLE,
} from "./actionTypes";
import {
  addRoleApi,
  deleteRoleApi,
  getRoleApi,
  getRolesApi,
  updateRoleApi,
} from "api/roles";

function* getRolesSaga({ payload }) {
  try {
    const { responseData } = yield call(getRolesApi, payload);
    yield put(getRolesSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(getRolesFailure(error.response?.data?.responseData?.message));
  }
}

// ============================================================
// ============================================================

function* getRoleSaga({ payload }) {
  try {
    const { responseData } = yield call(getRoleApi, payload);
    yield put(getRoleSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(getRoleFailure(error.response?.data?.responseData?.message));
  }
}

// ============================================================
// ============================================================

function* addRoleSaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(addRoleApi, payload);
    yield put(addRoleSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success("تمت الاضافة بنجاح");

      setTimeout(() => {
        payload?.navigate?.("/roles");
      }, 1000);
    }
  } catch (error) {
    console.log(error);
    yield put(addRoleFailure(error.response?.data?.responseData?.message));
    yield payload?.toast?.error(error.response?.data?.responseData?.message);
  }
}

// ============================================================
// ============================================================

function* updateRoleSaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(updateRoleApi, payload);
    yield put(updateRoleSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success("تم التعديل بنجاح");

      setTimeout(() => {
        payload?.navigate?.("/roles");
      }, 1000);
    }
  } catch (error) {
    console.log(error);
    yield put(updateRoleFailure(error.response?.data?.responseData?.message));
  }
}

// ============================================================
// ============================================================

function* deleteRoleSaga({ payload }) {
  try {
    yield call(deleteRoleApi, payload);
    yield put(deleteRoleSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deleteRoleFailure(error.response?.data?.responseData?.message));
  }
}

// ============================================================
// ============================================================

export function* watchGetRoles() {
  yield takeEvery(GET_ROLES, getRolesSaga);
}

// ============================================================

export function* watchRole() {
  yield takeEvery(GET_ROLE, getRoleSaga);
}

// ============================================================

export function* watchAddRole() {
  yield takeLatest(ADD_ROLE, addRoleSaga);
}

// ============================================================

export function* watchUpdateRole() {
  yield takeLatest(UPDATE_ROLE, updateRoleSaga);
}

// ============================================================

export function* watchDeleteRole() {
  yield takeLatest(DELETE_ROLE, deleteRoleSaga);
}

// ============================================================
// ============================================================

function* rolesSaga() {
  yield all([fork(watchGetRoles)]);
  yield all([fork(watchRole)]);
  yield all([fork(watchAddRole)]);
  yield all([fork(watchUpdateRole)]);
  yield all([fork(watchDeleteRole)]);
}

export default rolesSaga;
