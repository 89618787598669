export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAILURE = "GET_ADMINS_FAILURE";

//===========================================================
//===========================================================

export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";

//===========================================================
//===========================================================

export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILURE = "ADD_ADMIN_FAILURE";

//===========================================================
//===========================================================

export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";

//===========================================================
//===========================================================

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";

//===========================================================
//===========================================================
