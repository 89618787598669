import {
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_FAILURE,
  GET_ALL_SUBSCRIBERS_SUCCESS,
} from "./actionTypes";

export const getSubscribers = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBERS,
    payload,
  };
};

export const getSubscribersSuccess = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBERS_SUCCESS,
    payload,
  };
};

export const getSubscribersFailure = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBERS_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================
