import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getGuidelineListFailure, getGuidelineListSuccess } from "./actions";
import { GET_GUIDELINE_LIST } from "./actionTypes";
import { getGuidelineListApi } from "api/guideline";

function* getGuidelineListSaga({ payload }) {
  try {
    const { responseData } = yield call(getGuidelineListApi, payload);
    yield put(getGuidelineListSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getGuidelineListFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

export function* watchGuidelineList() {
  yield takeEvery(GET_GUIDELINE_LIST, getGuidelineListSaga);
}

// ============================================================
// ============================================================

function* guidelineListSaga() {
  yield all([fork(watchGuidelineList)]);
}

export default guidelineListSaga;
