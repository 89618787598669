import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

import "flatpickr/dist/themes/airbnb.css";

import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import searchIcon from "../../assets/svgs/search.svg";
import axios from "axios";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { assignCompany, removeAssignedCompany } from "store/actions";

const AssignCompany = ({ show, setShow, selectedUser, page, pageSize }) => {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [companiesPage, setCompaniesPage] = useState(1);
  const [companiesMetadata, setCompaniesMetadata] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});

  const handleSearch = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/etimad/GetEtimadCompanies?SearchValue=${searchValue}&pageSize=10&pageNumber=${companiesPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setCompanies(res?.data?.responseData?.items);
        setCompaniesMetadata({
          pageIndex: res?.data?.responseData?.pageIndex,
          count: res?.data?.responseData?.count,
          pageSize: res?.data?.responseData?.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetInputs = () => {
    setSearchValue("");
    setSelectedCompany({});
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      dialogClassName="modal-verify "
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          إسناد شركة ل {selectedUser?.firstName} {selectedUser?.lastName}
        </Modal.Title>
      </Modal.Header>
      <div className="verify-body assign-company">
        <Row>
          {selectedUser?.etimadCompany?.name && (
            <Col lg={12}>
              <div className="form-group">
                <h4>
                  <label>الشركة المسندة</label>
                  <button
                    type="button"
                    onClick={() =>
                      dispatch(
                        removeAssignedCompany({
                          data: {
                            accountId: selectedUser?.id,
                          },
                          toast,
                          dispatch,
                          page,
                          pageSize,
                          resetInputs,
                        })
                      )
                    }
                  >
                    حذف الشركة
                  </button>
                </h4>
                <div className="selected-company">
                  {selectedUser?.etimadCompany?.name}
                </div>
              </div>
            </Col>
          )}
          <Col lg={12} md={12}>
            <div className="form-group">
              <label>بحث عن الشركات</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="بحث عن الشركات"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={handleSearch}
                  disabled={!searchValue}
                >
                  <img src={searchIcon} alt="search" className="search" />
                </button>
              </div>
            </div>
          </Col>
          {companies?.length > 0 ? (
            <Col lg={12} md={12}>
              <div className="form-group">
                <label>الشركات المتاحة</label>
                <div className="companies-list">
                  {companies?.map((company) => (
                    <label className="form-check">
                      <input
                        type="radio"
                        name="company"
                        onChange={() => setSelectedCompany(company)}
                        checked={selectedCompany?.id === company?.id}
                      />
                      <span>{company?.name}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="table--pagination">
                <PaginationControl
                  page={companiesPage}
                  between={3}
                  total={companiesMetadata?.count || 0}
                  limit={companiesMetadata?.pageSize || 10}
                  changePage={(page) => {
                    setCompaniesPage(page);

                    axios
                      .get(
                        `${process.env.REACT_APP_API_URL}/etimad/GetEtimadCompanies?SearchValue=${searchValue}&pageSize=10&pageNumber=${page}`,
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        }
                      )
                      .then((res) => {
                        setCompanies(res?.data?.responseData?.items);
                        setCompaniesMetadata({
                          pageIndex: res?.data?.responseData?.pageIndex,
                          count: res?.data?.responseData?.count,
                          pageSize: res?.data?.responseData?.pageSize,
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  ellipsis={2}
                />
              </div>
            </Col>
          ) : (
            searchValue && (
              <Col lg={12}>
                <div className="form-group">
                  <h6 className="alert alert-warning text-center">
                    لا توجد شركات متاحة
                  </h6>
                </div>
              </Col>
            )
          )}
          <Col lg={12}>
            <div className="form-group has-btn">
              <button
                className="btn btn-blue"
                onClick={() => {
                  dispatch(
                    assignCompany({
                      data: {
                        accountId: selectedUser?.id,
                        etimadCompanyId: selectedCompany?.id,
                      },
                      toast,
                      dispatch,
                      page,
                      pageSize,
                      resetInputs,
                    })
                  );

                  setShow(false);
                }}
                disabled={!selectedCompany?.id}
              >
                إسناد
              </button>
            </div>
          </Col>
        </Row>
        {/* <div className="form-group">
      <label>تحديد عدد ايام التفعيل</label>
      <input
        type="number"
        className="form-control"
        value={numberOfDays}
        onChange={(e) => setNumberOfDays(e.target.value)}
        min={1}
      />
    </div> */}
      </div>
    </Modal>
  );
};

export default AssignCompany;
