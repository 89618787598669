import server from "./server";

export const getRolesApi = async ({ pageNumber, pageSize }) => {
  const response = await server().get(
    `/CmsManagment/GetRolesPagination?PageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response.data;
};

export const getRoleApi = async ({ id }) => {
  const response = await server().get(
    `/CmsManagment/GetRolePermissionById/${id}`
  );
  return response.data;
};

export const addRoleApi = async ({ data }) => {
  const response = await server().post("/CmsManagment/AddRolePermission", data);
  return response.data;
};

export const updateRoleApi = async ({ data }) => {
  const response = await server().put(
    "/CmsManagment/UpdateRolePermission",
    data
  );
  return response.data;
};

export const deleteRoleApi = async ({ id }) => {
  const response = await server().delete(`/CmsManagment/DeleteRoleById/${id}`);
  return response.data;
};
