import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  getAllSuggestionsFailure,
  getAllSuggestionsSuccess,
  getInquiriesFailure,
  getInquiriesSuccess,
} from "./actions";
import { GET_ALL_INQUIRIES, GET_ALL_SUGGESTIONS } from "./actionTypes";
import { getInquiriesApi, getSuggestionsApi } from "api/inquiries";

function* getAllInquiriesSaga({ payload }) {
  try {
    const { responseData } = yield call(getInquiriesApi, payload);
    yield put(getInquiriesSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(getInquiriesFailure(error.response?.data?.responseData?.message));
  }
}

// ============================================================
// ============================================================

function* getAllSuggestionsSaga({ payload }) {
  try {
    const { responseData } = yield call(getSuggestionsApi, payload);
    yield put(getAllSuggestionsSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getAllSuggestionsFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

export function* watchGetAllInquiries() {
  yield takeEvery(GET_ALL_INQUIRIES, getAllInquiriesSaga);
}

export function* watchGetAllSuggestions() {
  yield takeEvery(GET_ALL_SUGGESTIONS, getAllSuggestionsSaga);
}

// ============================================================
// ============================================================

function* inquiriesSaga() {
  yield all([fork(watchGetAllInquiries)]);
  yield all([fork(watchGetAllSuggestions)]);
}

export default inquiriesSaga;
