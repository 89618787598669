import {
  GET_ALL_INQUIRIES,
  GET_ALL_INQUIRIES_SUCCESS,
  GET_ALL_INQUIRIES_FAILURE,
  GET_ALL_SUGGESTIONS,
  GET_ALL_SUGGESTIONS_SUCCESS,
  GET_ALL_SUGGESTIONS_FAILURE,
} from "./actionTypes";

const initialState = {
  inquiries: [],
  suggestions: [],
  isLoggedIn: null,
  loading: false,
  error: "",
};

const inquiries = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INQUIRIES:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_INQUIRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        inquiries: action.payload,
      };

    case GET_ALL_INQUIRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case GET_ALL_SUGGESTIONS:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        suggestions: action.payload,
      };

    case GET_ALL_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    default:
      return state;
  }
};

export default inquiries;
