import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import blogs from "./blogs/reducer";
import categories from "./categories/reducer";
import subscribers from "./subscribers/reducer";
import inquiries from "./inquiries/reducer";
import sales from "./sales/reducer";
import guideline from "./guideline/reducer";
import admins from "./admins/reducer";
import roles from "./roles/reducer";
import permissions from "./permissions/reducer";

const rootReducer = combineReducers({
  authentication,
  blogs,
  categories,
  subscribers,
  inquiries,
  sales,
  guideline,
  admins,
  roles,
  permissions,
});

export default rootReducer;
