import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import BlogsSaga from "./blogs/saga";
import CategoriesSaga from "./categories/saga";
import SubscribersSaga from "./subscribers/saga";
import InquiriesSaga from "./inquiries/saga";
import SalesSaga from "./sales/saga";
import guidelineListSaga from "./guideline/saga";
import AdminsSaga from "./admins/saga";
import RolesSaga from "./roles/saga";
import PermissionsSaga from "./permissions/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    BlogsSaga(),
    CategoriesSaga(),
    SubscribersSaga(),
    InquiriesSaga(),
    SalesSaga(),
    guidelineListSaga(),
    AdminsSaga(),
    RolesSaga(),
    PermissionsSaga(),
  ]);
}
