import BlocksTop from "components/Home/BlocksTop";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getHealthStatus } from "store/actions";
import Table from "./Table";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { healthStatus } = useSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(getHealthStatus());
  }, [dispatch]);

  return (
    <div>
      <div className="health-status">
        <Table healthStatus={healthStatus} />
      </div>
      <BlocksTop />
    </div>
  );
};

export default Dashboard;
