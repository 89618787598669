import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";

import { useLocation } from "react-router-dom";
import { EditorComponent } from "components/shared/FormComponents";
import toast from "react-hot-toast";

const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname.split("/")[1];

  const [data, setData] = useState([]);

  const [description, setDescription] = useState("");

  const { handleSubmit, control } = useForm();

  const onStart = async () => {
    const pageData = await server().get(`/Cms/GetCmsSectionBySlugName/${slug}`);
    setData(pageData.data.responseData);
    setDescription(pageData?.data?.responseData?.description);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (formData) => {
    formData.description = description;
    try {
      const savedResponse = await server().put(
        `/Cms/EditCmsSectionBySlugName`,
        {
          ...formData,
          slug: slug,
          id: data.id,
          items: data.items,
          subTitle: data.subTitle,
          sectionId: data.sectionId,
        }
      );

      if (savedResponse.data.responseData) {
        toast.success("تم تعديل البيانات بنجاح");
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>الشروط والأحكام</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>محتوى الصفحة</h5>

                      <Controller
                        control={control}
                        name="description"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="description"
                            setData={setDescription}
                            initialValue={data?.description}
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
