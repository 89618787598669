export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE";

//===========================================================
//===========================================================

export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAILURE = "GET_PERMISSION_FAILURE";

//===========================================================
//===========================================================

export const ADD_PERMISSION = "ADD_PERMISSION";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILURE = "ADD_PERMISSION_FAILURE";

//===========================================================
//===========================================================

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAILURE = "UPDATE_PERMISSION_FAILURE";

//===========================================================
//===========================================================

export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAILURE = "DELETE_PERMISSION_FAILURE";

//===========================================================
//===========================================================
