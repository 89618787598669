import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import inquiriesIcon from "assets/svgs/info.svg";
import { useDispatch } from "react-redux";
import { getAllSuggestions, getInquiries } from "store/inquiries/actions";
import { Nav, Tab } from "react-bootstrap";
import InquiriesTable from "./InquiriesTable";
import SuggestionsTable from "./SuggestionsTable";
const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInquiries());
    dispatch(getAllSuggestions());
  }, [dispatch]);

  return (
    <>
      <div className="users-wrap">
        <ControlArea cardTxt="استفسارات و اقتراحات" icon={inquiriesIcon} />
        <div className="mini-tabs">
          <Tab.Container defaultActiveKey={0} transition={true} timeout={1000}>
            <Nav>
              <Nav.Item>
                <Nav.Link eventKey={0}>الاستفسارات</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={1}>الاقتراحات</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={0}>
                <InquiriesTable />
              </Tab.Pane>
              <Tab.Pane eventKey={1}>
                <SuggestionsTable />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Index;
