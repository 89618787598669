import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Items from "./Items";
import { EditorComponent } from "components/shared/FormComponents";
const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [description, setDescription] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onStart = async () => {
    const pageData = await server().get(`/Cms/GetCmsSectionBySlugName/${slug}`);
    setData(pageData.data.responseData || {});
    setItems(pageData?.data?.responseData?.items || []);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      title: data?.title,
      buttonLabel: data?.buttonLabel,
      buttonUrl: data?.buttonUrl,
      items: data?.items,
      isActive: data?.isActive,
    });

    setDescription(data?.description);
  }, [reset, data]);

  const onSubmit = async (formData) => {
    formData.items = items;
    formData.description = description;

    try {
      const savedResponse = await server().put(
        `/Cms/EditCmsSectionBySlugName`,
        {
          ...formData,
          slug: slug,
          id: data.id,
          subTitle: data.subTitle,
          sectionId: data.sectionId,
        }
      );

      if (savedResponse.data.responseData) {
        toast.success("تم تعديل البيانات بنجاح");
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>دليل نافس</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xxl={8} lg={12} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>عنوان رئيسي</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="عنوان رئيسي"
                          {...register("title", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.title?.type === "required" &&
                          "يرجي ادخال  عنوان رئيسي"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5> الحالة (مفعل / غير مفعل)</h5>

                      <div className="theme-switcher">
                        <input
                          type="checkbox"
                          id="themeSwitcher"
                          {...register("isActive")}
                        />
                        <label htmlFor="themeSwitcher"></label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>وصف</h5>
                      <div>
                        <EditorComponent
                          name="description"
                          setData={setDescription}
                          initialValue={data?.description}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.description?.type === "required" &&
                          "يرجي ادخال  وصف"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>محتوي الزر</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="محتوي الزر"
                          {...register("buttonLabel", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.buttonLabel?.type === "required" &&
                          "يرجي ادخال محتوي الزر"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رابط الزر</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رابط الزر"
                          {...register("buttonUrl", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.buttonUrl?.type === "required" &&
                          "يرجي ادخال رابط الزر"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <Items features={items} setFeatures={setItems} />
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
