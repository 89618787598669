import {
  GET_GUIDELINE_LIST,
  GET_GUIDELINE_LIST_FAILURE,
  GET_GUIDELINE_LIST_SUCCESS,
} from "./actionTypes";

export const getGuidelineList = (payload) => {
  return {
    type: GET_GUIDELINE_LIST,
    payload,
  };
};

export const getGuidelineListSuccess = (payload) => {
  return {
    type: GET_GUIDELINE_LIST_SUCCESS,
    payload,
  };
};

export const getGuidelineListFailure = (payload) => {
  return {
    type: GET_GUIDELINE_LIST_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================
