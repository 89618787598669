import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Items from "./Items";
import imageUpload from "../../../assets/svgs/imageUpload.svg";
import { EditorComponent } from "components/shared/FormComponents";

const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const [description, setDescription] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onStart = async () => {
    const pageData = await server().get(`/Cms/GetCmsSectionBySlugName/${slug}`);
    setData(pageData.data.responseData || {});
    setItems(pageData?.data?.responseData?.items || []);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      title: data?.title,
      buttonLabel: data?.buttonLabel,
      buttonUrl: data?.buttonUrl,
      items: data?.items,
    });
    setSelectedImage({
      preview: data?.image,
      path: data?.image,
    });
    setDescription(data?.description);
  }, [reset, data]);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post(
        "/CmsManagment/UploadAnonymousIamge",
        formData
      );
      setSelectedImage({
        preview: e.target.files[0],
        path: data.responseData.path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = async (formData) => {
    formData.items = items;
    if (selectedImage?.preview !== undefined)
      formData.image = selectedImage?.path || "";
    formData.description = description;

    try {
      const savedResponse = await server().put(
        `/Cms/EditCmsSectionBySlugName`,
        {
          ...formData,
          slug: slug,
          id: data.id,
          subTitle: data.subTitle,
          sectionId: data.sectionId,
        }
      );

      if (savedResponse.data.responseData) {
        toast.success("تم تعديل البيانات بنجاح");
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>محتوي من نحن</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xxl={8} lg={12} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة جانبية</h5>
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={selectedImage?.path}
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.image?.type === "required" &&
                            "يرجي اختيار صورة "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>عنوان رئيسي</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="عنوان رئيسي"
                          {...register("title", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.title?.type === "required" &&
                          "يرجي ادخال  عنوان رئيسي"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>وصف</h5>
                      <div>
                        <EditorComponent
                          name="description"
                          setData={setDescription}
                          initialValue={data?.description}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.description?.type === "required" &&
                          "يرجي ادخال  وصف"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>محتوي الزر</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="محتوي الزر"
                          {...register("buttonLabel", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.buttonLabel?.type === "required" &&
                          "يرجي ادخال محتوي الزر"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رابط الزر</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رابط الزر"
                          {...register("buttonUrl", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.buttonUrl?.type === "required" &&
                          "يرجي ادخال رابط الزر"}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <Items features={items} setFeatures={setItems} />
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
