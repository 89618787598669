import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import Table from "./Table";
import blogCategoriesIcon from "assets/svgs/blog-categories.svg";
import { useDispatch } from "react-redux";
import { getCategories } from "store/actions";
// import {
//   EditorComposer,
//   Editor,
//   ToolbarPlugin,
//   AlignDropdown,
//   BackgroundColorPicker,
//   BoldButton,
//   CodeFormatButton,
//   FloatingLinkEditor,
//   FontFamilyDropdown,
//   FontSizeDropdown,
//   InsertDropdown,
//   InsertLinkButton,
//   ItalicButton,
//   TextColorPicker,
//   TextFormatDropdown,
//   UnderlineButton,
//   Divider,
// } from "verbum";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);
  // const [value, setValue] = React.useState("");

  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt="إضافة تصنيف جديد"
          cardTxt="التصنيفات"
          icon={blogCategoriesIcon}
          url="/categories/add"
        />
        <Table />
        {/* <EditorComposer>
          <Editor hashtagsEnabled={true}>
            <ToolbarPlugin defaultFontSize="20px">
              <FontFamilyDropdown />
              <FontSizeDropdown />
              <Divider />
              <BoldButton />
              <ItalicButton />
              <UnderlineButton />
              <CodeFormatButton />
              <InsertLinkButton />
              <TextColorPicker />
              <BackgroundColorPicker />
              <TextFormatDropdown />
              <Divider />
              <InsertDropdown enablePoll={true} />
              <Divider />
              <AlignDropdown />
            </ToolbarPlugin>
          </Editor>
        </EditorComposer> */}
        {/* <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }, { font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [{ color: [] }, { background: [] }],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image", "video"],
              ["clean"],
            ],
          }}
        /> */}
      </div>
    </>
  );
};

export default Index;
