import { getSubscribersApi } from "api/subscribers";
import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getSubscribersFailure, getSubscribersSuccess } from "./actions";
import { GET_ALL_SUBSCRIBERS } from "./actionTypes";

function* getAllSubscribersSaga({ payload }) {
  try {
    const { responseData } = yield call(getSubscribersApi, payload);
    yield put(getSubscribersSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getSubscribersFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

export function* watchGetAllSubscribers() {
  yield takeEvery(GET_ALL_SUBSCRIBERS, getAllSubscribersSaga);
}

// ============================================================
// ============================================================

function* subscribersSaga() {
  yield all([fork(watchGetAllSubscribers)]);
}

export default subscribersSaga;
