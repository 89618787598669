import {
  DELETE_CATEGORY,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_FAILURE,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_FAILURE,
  GET_SINGLE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_SUCCESS,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
} from "./actionTypes";

export const getCategories = (payload) => {
  return {
    type: GET_ALL_CATEGORIES,
    payload,
  };
};

export const getCategoriesSuccess = (payload) => {
  return {
    type: GET_ALL_CATEGORIES_SUCCESS,
    payload,
  };
};

export const getCategoriesFailure = (payload) => {
  return {
    type: GET_ALL_CATEGORIES_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const getSingleCategory = (payload) => {
  return {
    type: GET_SINGLE_CATEGORY,
    payload,
  };
};

export const getSingleCategorySuccess = (payload) => {
  return {
    type: GET_SINGLE_CATEGORY_SUCCESS,
    payload,
  };
};

export const getSingleCategoryFailure = (payload) => {
  return {
    type: GET_SINGLE_CATEGORY_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const addCategory = (payload) => {
  return {
    type: ADD_CATEGORY,
    payload,
  };
};

export const addCategorySuccess = (payload) => {
  return {
    type: ADD_CATEGORY_SUCCESS,
    payload,
  };
};

export const addCategoryFailure = (payload) => {
  return {
    type: ADD_CATEGORY_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const updateCategory = (payload) => {
  return {
    type: UPDATE_CATEGORY,
    payload,
  };
};

export const updateCategorySuccess = (payload) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload,
  };
};

export const updateCategoryFailure = (payload) => {
  return {
    type: UPDATE_CATEGORY_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const deleteCategory = (payload) => {
  return {
    type: DELETE_CATEGORY,
    payload,
  };
};

export const deleteCategorySuccess = (payload) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload,
  };
};

export const deleteCategoryFailure = (payload) => {
  return {
    type: DELETE_CATEGORY_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================
