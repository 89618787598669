import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import Table from "./Table";
import subscribersIcon from "assets/svgs/subscribers.svg";
import { useDispatch } from "react-redux";
import { getGuidelineList } from "store/actions";
const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGuidelineList());
  }, [dispatch]);

  return (
    <>
      <div className="users-wrap">
        <ControlArea cardTxt="مشتركين دليل نافس" icon={subscribersIcon} />
        <Table />
      </div>
    </>
  );
};

export default Index;
