import {
  GET_ALL_INQUIRIES,
  GET_ALL_INQUIRIES_FAILURE,
  GET_ALL_INQUIRIES_SUCCESS,
  GET_ALL_SUGGESTIONS,
  GET_ALL_SUGGESTIONS_FAILURE,
  GET_ALL_SUGGESTIONS_SUCCESS,
} from "./actionTypes";

export const getInquiries = (payload) => {
  return {
    type: GET_ALL_INQUIRIES,
    payload,
  };
};

export const getInquiriesSuccess = (payload) => {
  return {
    type: GET_ALL_INQUIRIES_SUCCESS,
    payload,
  };
};

export const getInquiriesFailure = (payload) => {
  return {
    type: GET_ALL_INQUIRIES_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const getAllSuggestions = (payload) => {
  return {
    type: GET_ALL_SUGGESTIONS,
    payload,
  };
};

export const getAllSuggestionsSuccess = (payload) => {
  return {
    type: GET_ALL_SUGGESTIONS_SUCCESS,
    payload,
  };
};

export const getAllSuggestionsFailure = (payload) => {
  return {
    type: GET_ALL_SUGGESTIONS_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================
