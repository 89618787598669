import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard/Index";
import NotFound from "views/NotFound";
import Login from "views/login";
import ForgetPassword from "views/forget-password";
import Logout from "views/Logout";
import { getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

//Home Sections
import HeroIndex from "views/home/heroSection/Index";
import FeaturesIndex from "views/home/features/Index";
import UseCasesIndex from "views/home/useCases/Index";
import ResultsIndex from "views/home/results/Index";
import TestimonialIndex from "views/home/testimonials/Index";
import GuideIndex from "views/home/guide/Index";
import PartnersIndex from "views/home/partners/Index";
import LoginIndex from "views/home/login/Index";
import RegisterIndex from "views/home/register/Index";
import ForgetPasswordIndex from "views/home/forgetPassword/Index";
import ResetPasswordIndex from "views/home/resetPassword/Index";
import ScheduleMeetingIndex from "views/home/scheduleMeeting/Index";
import RequestDemoIndex from "views/home/requestDemo/Index";
import InstructionsIndex from "views/home/instructions/Index";
import AboutIndex from "views/home/about/Index";
import AboutFeaturesIndex from "views/home/aboutFeatures/Index";
import BlogsContentIndex from "views/home/blogs/Index";
import AboutStatistics from "views/home/aboutStatistics/Index";
import WaitingListIndex from "views/home/waitingList/Index";
import StepsContentIndex from "views/home/steps/Index";
import ThankYouIndex from "views/home/thank-you/Index";
import WaitingListThankYouIndex from "views/home/waiting-list-thank-you/Index";

import Privacy from "views/privacy/Index";

//settings
import Settings from "views/settings/Index";

//admins
import Admins from "views/admins/Index";
import AddAdmin from "views/admins/AddAdmin";
import EditAdmin from "views/admins/EditAdmin";

//blogs
import Blogs from "views/blogs/Index";
import AddBlog from "views/blogs/AddBlog";
import EditBlog from "views/blogs/EditBlog";

//blog categories
import BlogCategories from "views/categories/Index";
import AddBlogCategory from "views/categories/AddCategory";
import EditBlogCategory from "views/categories/EditCategory";

//seo
import SeoHome from "views/seo/home/Index";
import SeoAbout from "views/seo/about/Index";
import SeoBlogs from "views/seo/blogs/Index";
import SeoLogin from "views/seo/login/Index";
import SeoRegister from "views/seo/register/Index";
import SeoForgetPassword from "views/seo/forgetPassword/Index";
import SeoResetPassword from "views/seo/resetPassword/Index";
import SeoScheduleMeeting from "views/seo/scheduleMeeting/Index";
import SeoRequestDemo from "views/seo/requestDemo/Index";
import SeoInstructions from "views/seo/instructions/Index";
import SeoAccount from "views/seo/account/Index";
import SeoPrivacy from "views/seo/privacy/Index";
import WaitingListSeo from "views/seo/waitingList/Index";
import ThankYouSeo from "views/seo/thank-you/Index";
import WaitingListThankYouSeo from "views/seo/waiting-list-thank-you/Index";
import LandingPageSeo from "views/seo/landing-page/Index";

//subscribers
import Subscribers from "views/subscribers/Index";

// guideLine
import GuidelineList from "views/guideline-list/Index";

// Inquiries
import Inquiries from "views/inquiries-and-suggestions/Index";

//sales
import SalesRequests from "views/sales-requests/Index";

// landing sections
import Intro from "views/landing/intro/Index";
import AboutNafes from "views/landing/about-nafes/Index";
import PartnersNafes from "views/landing/partners-nafes/Index";
import FeaturesNafes from "views/landing/features-nafes/Index";
import HelpNafes from "views/landing/help-nafes/Index";
import VisionNafes from "views/landing/vision-nafes/Index";
import GuideNafes from "views/landing/guide-nafes/Index";
import JoinNafes from "views/landing/join/Index";

// users
import Users from "views/users/Index";
import EditUser from "views/users/Edit";

//roles
import Roles from "views/roles/Index";
import AddRole from "views/roles/Add";
import EditRole from "views/roles/Edit";

//permissions
import Permissions from "views/permissions/Index";
import AddPermission from "views/permissions/Add";
import EditPermission from "views/permissions/Edit";

//terms
import Terms from "views/terms/Index";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn } = useSelector((state) => state?.authentication);

  useEffect(() => {
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />

              {/*Home Sections */}
              <Route path="/home/hero" element={<HeroIndex />} />
              <Route path="/home/partners" element={<PartnersIndex />} />
              <Route path="/home/features" element={<FeaturesIndex />} />
              <Route path="/home/use-cases" element={<UseCasesIndex />} />
              <Route path="/home/results" element={<ResultsIndex />} />
              <Route path="/home/testimonials" element={<TestimonialIndex />} />
              <Route path="/home/guide" element={<GuideIndex />} />
              <Route path="/home/login" element={<LoginIndex />} />
              <Route path="/home/register" element={<RegisterIndex />} />
              <Route
                path="/home/forget-password"
                element={<ForgetPasswordIndex />}
              />
              <Route
                path="/home/reset-password"
                element={<ResetPasswordIndex />}
              />
              <Route
                path="/home/schedule-meeting"
                element={<ScheduleMeetingIndex />}
              />
              <Route path="/home/request-demo" element={<RequestDemoIndex />} />
              <Route
                path="/home/instructions"
                element={<InstructionsIndex />}
              />
              <Route path="/home/about" element={<AboutIndex />} />
              <Route
                path="/home/about-features"
                element={<AboutFeaturesIndex />}
              />
              <Route
                path="/home/about-statistics"
                element={<AboutStatistics />}
              />
              <Route path="/home/blogs" element={<BlogsContentIndex />} />
              <Route path="/home/waiting-list" element={<WaitingListIndex />} />
              <Route path="/home/steps" element={<StepsContentIndex />} />
              <Route path="/home/thank-you" element={<ThankYouIndex />} />
              <Route
                path="/home/waiting-list-thank-you"
                element={<WaitingListThankYouIndex />}
              />

              {/*privacy */}
              <Route path="/privacy" element={<Privacy />} />

              {/* terms */}
              <Route path="/terms-and-conditions" element={<Terms />} />

              {/* settings */}
              <Route path="/settings" element={<Settings />} />

              {/*admins */}
              <Route path="/admins" element={<Admins />} />
              <Route path="/admins/add" element={<AddAdmin />} />
              <Route path="/admins/:id" element={<EditAdmin />} />

              {/*blogs */}
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/add" element={<AddBlog />} />
              <Route path="/blogs/:id" element={<EditBlog />} />

              {/* blog categories */}
              <Route path="/categories" element={<BlogCategories />} />
              <Route path="/categories/add" element={<AddBlogCategory />} />
              <Route path="/categories/:id" element={<EditBlogCategory />} />

              {/* Seo Sections */}
              <Route path="/seo/home" element={<SeoHome />} />
              <Route path="/seo/about" element={<SeoAbout />} />
              <Route path="/seo/blogs" element={<SeoBlogs />} />
              <Route path="/seo/login" element={<SeoLogin />} />
              <Route path="/seo/register" element={<SeoRegister />} />
              <Route
                path="/seo/forget-password"
                element={<SeoForgetPassword />}
              />
              <Route
                path="/seo/reset-password"
                element={<SeoResetPassword />}
              />
              <Route
                path="/seo/schedule-meeting"
                element={<SeoScheduleMeeting />}
              />
              <Route path="/seo/request-demo" element={<SeoRequestDemo />} />
              <Route path="/seo/instructions" element={<SeoInstructions />} />
              <Route path="/seo/account" element={<SeoAccount />} />
              <Route path="/seo/privacy-policy" element={<SeoPrivacy />} />
              <Route path="/seo/waiting-list" element={<WaitingListSeo />} />
              <Route path="/seo/thank-you" element={<ThankYouSeo />} />
              <Route
                path="/seo/waiting-list-thank-you"
                element={<WaitingListThankYouSeo />}
              />
              <Route path="/seo/landing-page" element={<LandingPageSeo />} />

              <Route path="/subscribers" element={<Subscribers />} />

              <Route
                path="/inquiries-and-suggestions"
                element={<Inquiries />}
              />

              <Route path="/sales-requests" element={<SalesRequests />} />

              <Route path="/guideline-list" element={<GuidelineList />} />

              {/* landing pages */}
              <Route path="/landing/intro" element={<Intro />} />
              <Route path="/landing/about-nafes" element={<AboutNafes />} />
              <Route
                path="/landing/partners-nafes"
                element={<PartnersNafes />}
              />
              <Route
                path="/landing/features-nafes"
                element={<FeaturesNafes />}
              />
              <Route path="/landing/help-nafes" element={<HelpNafes />} />
              <Route path="/landing/vision-nafes" element={<VisionNafes />} />
              <Route path="/landing/guide-nafes" element={<GuideNafes />} />
              <Route path="/landing/join-nafes" element={<JoinNafes />} />

              {/*users */}
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<EditUser />} />

              {/*roles */}
              <Route path="/roles" element={<Roles />} />
              <Route path="/roles/add" element={<AddRole />} />
              <Route path="/roles/:id" element={<EditRole />} />

              {/*permissions */}
              <Route path="/permissions" element={<Permissions />} />
              <Route path="/permissions/add" element={<AddPermission />} />
              <Route path="/permissions/:id" element={<EditPermission />} />

              <Route path="*" element={<NotFound />} />
            </Route>

            {/*Auth Routes */}
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
            <Route
              path="/forget-password"
              element={
                <GuestRoute>
                  <ForgetPassword />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
