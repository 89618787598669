import { getSalesRequestsApi } from "api/sales";
import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { getSalesRequestsFailure, getSalesRequestsSuccess } from "./actions";
import { GET_ALL_SALES_REQUESTS } from "./actionTypes";

function* getAllSalesRequestsSaga({ payload }) {
  try {
    const { data } = yield call(getSalesRequestsApi, payload);
    yield put(getSalesRequestsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getSalesRequestsFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

export function* watchGetAllSalesRequests() {
  yield takeEvery(GET_ALL_SALES_REQUESTS, getAllSalesRequestsSaga);
}

// ============================================================
// ============================================================

function* salesRequestsSaga() {
  yield all([fork(watchGetAllSalesRequests)]);
}

export default salesRequestsSaga;
