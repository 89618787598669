import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import Table from "./Table";
import requestsIcon from "assets/svgs/support.svg";
import { useDispatch } from "react-redux";
import { getSalesRequests } from "store/actions";
const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesRequests());
  }, [dispatch]);

  return (
    <>
      <div className="users-wrap">
        <ControlArea cardTxt="طلبات المستخدمين" icon={requestsIcon} />
        <Table />
      </div>
    </>
  );
};

export default Index;
