import server from "./server";

export const getPermissionsApi = async ({ pageNumber, pageSize }) => {
  const response = await server().get(
    `/CmsManagment/GetPermissionsPagination?PageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response.data;
};

export const getPermissionApi = async ({ id }) => {
  const response = await server().get(
    `/CmsManagment/GetPermissionById?permissionId=${id}`
  );
  return response.data;
};

export const addPermissionApi = async ({ data }) => {
  const response = await server().post("/CmsManagment/AddPermission", data);
  return response.data;
};

export const updatePermissionApi = async ({ data }) => {
  const response = await server().put(
    "/CmsManagment/UpdatePermissionById",
    data
  );
  return response.data;
};

export const deletePermissionApi = async ({ id }) => {
  const response = await server().delete(
    `/CmsManagment/DeletePermissionById/${id}`
  );
  return response.data;
};
