import {
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_FAILURE,
  GET_SINGLE_CATEGORY,
  GET_SINGLE_CATEGORY_SUCCESS,
  GET_SINGLE_CATEGORY_FAILURE,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
} from "./actionTypes";

const initialState = {
  categories: [],
  singleCategory: {},
  isLoggedIn: null,
  loading: false,
  error: "",
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case GET_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case GET_SINGLE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case GET_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCategory: action.payload,
      };

    case GET_SINGLE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case ADD_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: [...state.categories, action.payload],
      };

    case ADD_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case UPDATE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.map((blog) =>
          blog.id === action.payload.id ? action.payload : blog
        ),
      };

    case UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter(
          (blog) => blog?.id !== action.payload?.id
        ),
      };

    case DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    default:
      return state;
  }
};

export default categories;
