const { default: server } = require("./server");

export const getBlogsApi = async () => {
  const response = await server().get(`/Blog/GetAllBlogs`);
  return response.data;
};

export const getSingleBlogApi = async ({ id }) => {
  const response = await server().get(`/Blog/GetBlogById/${id}`);
  return response.data;
};

export const addBlogApi = async ({ data }) => {
  const response = await server().post(`/Blog/AddBlog`, data);
  return response.data;
};

export const updateBlogApi = async ({ data }) => {
  const response = await server().put(`/Blog/EditBlogById`, data);
  return response.data;
};

export const deleteBlogApi = async ({ slug }) => {
  const response = await server().delete(`/Blog/DeleteBlogBySlugName/${slug}`);
  return response.data;
};
