import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  addUserModule,
  getAllModules,
  getUserModules,
  updateUserModules,
} from "store/actions";
import Loader from "components/shared/Loader";

const UserPlans = ({ show, setShow, selectedUser }) => {
  const dispatch = useDispatch();

  const { modules, singleModule, loading } = useSelector(
    (state) => state.authentication
  );

  const [selectedModules, setSelectedModules] = useState([]);

  useEffect(() => {
    if (selectedUser) {
      dispatch(getAllModules());
      dispatch(
        getUserModules({
          useerId: selectedUser?.id,
        })
      );
    }
  }, [dispatch, selectedUser]);

  useEffect(() => {
    if (singleModule?.length > 0) {
      setSelectedModules(singleModule?.map((module) => module?.id));
    }
  }, [singleModule]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      dialogClassName="modal-verify"
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>صلاحيات المستخدم </Modal.Title>
      </Modal.Header>
      <div className="verify-body userPermissions">
        {loading ? (
          <Loader />
        ) : (
          <>
            {modules?.map((module, index) => {
              return (
                <div className="form-group" key={index}>
                  <label className="form-check">
                    <input
                      type="checkbox"
                      checked={selectedModules?.includes(module?.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedModules([...selectedModules, module?.id]);
                        } else {
                          setSelectedModules(
                            selectedModules?.filter((id) => id !== module?.id)
                          );
                        }
                      }}
                    />
                    {module?.name}
                  </label>
                </div>
              );
            })}

            <div className="form-group">
              <button
                className="btn btn-blue"
                type="button"
                onClick={() => {
                  if (selectedModules?.length === 0) {
                    return toast.error("يجب اختيار صلاحية واحدة على الأقل");
                  }

                  if (singleModule) {
                    dispatch(
                      updateUserModules({
                        data: {
                          userId: selectedUser?.id,
                          moduleId: selectedModules,
                        },
                        toast,
                        setShow,
                        dispatch,
                      })
                    );
                  } else
                    dispatch(
                      addUserModule({
                        data: {
                          userId: selectedUser?.id,
                          moduleId: selectedModules,
                        },
                        toast,
                        setShow,
                        dispatch,
                      })
                    );
                }}
              >
                حفظ
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UserPlans;
