import {
  GET_GUIDELINE_LIST,
  GET_GUIDELINE_LIST_FAILURE,
  GET_GUIDELINE_LIST_SUCCESS,
} from "./actionTypes";

const initialState = {
  guidelineList: [],
  isLoggedIn: null,
  loading: false,
  error: "",
};

const guidelineList = (state = initialState, action) => {
  switch (action.type) {
    case GET_GUIDELINE_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_GUIDELINE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        guidelineList: action.payload,
      };

    case GET_GUIDELINE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    default:
      return state;
  }
};

export default guidelineList;
