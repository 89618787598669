import {
  DELETE_ROLE,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
  GET_ROLES,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  GET_ROLE,
  GET_ROLE_FAILURE,
  GET_ROLE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
} from "./actionTypes";

export const getRoles = (payload) => {
  return {
    type: GET_ROLES,
    payload,
  };
};

export const getRolesSuccess = (payload) => {
  return {
    type: GET_ROLES_SUCCESS,
    payload,
  };
};

export const getRolesFailure = (payload) => {
  return {
    type: GET_ROLES_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const getRole = (payload) => {
  return {
    type: GET_ROLE,
    payload,
  };
};

export const getRoleSuccess = (payload) => {
  return {
    type: GET_ROLE_SUCCESS,
    payload,
  };
};

export const getRoleFailure = (payload) => {
  return {
    type: GET_ROLE_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const addRole = (payload) => {
  return {
    type: ADD_ROLE,
    payload,
  };
};

export const addRoleSuccess = (payload) => {
  return {
    type: ADD_ROLE_SUCCESS,
    payload,
  };
};

export const addRoleFailure = (payload) => {
  return {
    type: ADD_ROLE_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const updateRole = (payload) => {
  return {
    type: UPDATE_ROLE,
    payload,
  };
};

export const updateRoleSuccess = (payload) => {
  return {
    type: UPDATE_ROLE_SUCCESS,
    payload,
  };
};

export const updateRoleFailure = (payload) => {
  return {
    type: UPDATE_ROLE_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const deleteRole = (payload) => {
  return {
    type: DELETE_ROLE,
    payload,
  };
};

export const deleteRoleSuccess = (payload) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload,
  };
};

export const deleteRoleFailure = (payload) => {
  return {
    type: DELETE_ROLE_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================
