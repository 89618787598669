import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { sidebarData } from "../../data";
import TopBlockItem from "./TopBlockItem";
import { useSelector } from "react-redux";

const BlocksTop = () => {
  const { user } = useSelector((state) => state.authentication);

  const [filteredSidebarData, setFilteredSidebarData] = useState([]);

  useEffect(() => {
    if (user) {
      const filteredData = sidebarData.filter((item) => {
        if (item.permissions) {
          return item.permissions.some((permission) =>
            user.roles.some((role) =>
              role.permissions.some(
                (rolePermission) => rolePermission.name === permission
              )
            )
          );
        } else {
          return true;
        }
      });
      setFilteredSidebarData(filteredData);
    }
  }, [user]);

  const topCards = filteredSidebarData?.map((topcard, index) => {
    return <TopBlockItem key={index} topcard={topcard} />;
  });
  return (
    <>
      <div className="topCards">
        <Row>{topCards}</Row>
      </div>
    </>
  );
};

export default BlocksTop;
