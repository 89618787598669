import ControlArea from "components/Layout/ControlArea";
import React from "react";
import Table from "./Table";
import adminsIcon from "assets/svgs/permissions.svg";
import { useSelector } from "react-redux";
import BootyPagination from "components/shared/Pagination";
const Index = () => {
  const { permissions, metadata } = useSelector((state) => state.permissions);

  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt=" اضافة صلاحية جديدة"
          cardTxt="صلاحيات المستخدمين"
          icon={adminsIcon}
          url="/permissions/add"
        />
        <Table permissions={permissions} />
        {metadata?.count > 8 && <BootyPagination metadata={metadata} />}
      </div>
    </>
  );
};

export default Index;
