import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions, getRole, updateRole } from "store/actions";
import toast from "react-hot-toast";
import Loader from "components/shared/Loader";

const EditAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { role, loading } = useSelector((state) => state.roles);
  const { permissions } = useSelector((state) => state.permissions);

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    dispatch(getRole({ id }));
    dispatch(
      getPermissions({
        pageNumber: 1,
        pageSize: 1000,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      roleName: role?.roleName,
    });

    setSelectedPermissions(
      role?.permissions?.map((permission) => permission?.permissionId)
    );
  }, [role, reset]);

  const handleCheckboxChange = (permissionId) => {
    setSelectedPermissions((prevPermissions) => {
      if (prevPermissions?.includes(permissionId)) {
        return prevPermissions?.filter((id) => id !== permissionId);
      } else {
        return [...prevPermissions, permissionId];
      }
    });
  };

  const onSubmit = (data) => {
    data.roleId = id;
    data.permissionId = selectedPermissions;

    dispatch(
      updateRole({
        data,
        navigate,
        toast,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل البيانات</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>الاسم</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="الاسم"
                          {...register("roleName", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.roleName && "يرجي ادخال الاسم "}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="permissions">
                      <div className="card-head">
                        <h4>الصلاحيات</h4>
                      </div>
                      <div className="card-body">
                        {permissions?.map((permission, index) => (
                          <label key={index}>
                            <input
                              type="checkbox"
                              checked={selectedPermissions?.includes(
                                permission?.id
                              )}
                              onChange={() =>
                                handleCheckboxChange(permission?.id)
                              }
                            />
                            <span>{permission?.name}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
