import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";

import Filter from "components/shared/Filter";
import Loader from "components/shared/Loader";

import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

const Table = () => {
  const [pending, setPending] = useState(true);
  const { guidelineList } = useSelector((state) => state.guideline);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = guidelineList?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "البريد الإلكتروني",
      selector: (row) => row?.email,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id="guidelineList" />
                </h4>
                <p>
                  يعرض هذا الجدول <FormattedMessage id="guidelineList" />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
