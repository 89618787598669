import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import server from "api/server";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Items from "./Items";

const Index = () => {
  const { pathname } = useLocation();
  const slug = pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);

  const { handleSubmit, reset } = useForm();

  const onStart = async () => {
    const pageData = await server().get(`/Cms/GetCmsSectionBySlugName/${slug}`);
    setData(pageData.data.responseData || {});
    setItems(pageData?.data?.responseData?.items || []);
  };

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      items: data?.items,
      isActive: data?.isActive,
    });
  }, [reset, data]);

  const onSubmit = async (formData) => {
    formData.items = items;

    try {
      const savedResponse = await server().put(
        `/Cms/EditCmsSectionBySlugName`,
        {
          ...formData,
          slug: slug,
          id: data.id,
          subTitle: data.subTitle,
          sectionId: data.sectionId,
          title: data.title,
          description: data.description,
          image: data.image,
          buttonLabel: data.buttonLabel,
          buttonUrl: data.buttonUrl,
        }
      );

      if (savedResponse.data.responseData) {
        toast.success("تم تعديل البيانات بنجاح");
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    }
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>محتوي خطوات تسجيل حساب </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xxl={8} lg={12} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <Items features={items} setFeatures={setItems} />
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
