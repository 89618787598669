const { default: server } = require("./server");

export const getInquiriesApi = async () => {
  const response = await server().get(`/GetAllInquiries`);
  return response.data;
};

export const getSuggestionsApi = async () => {
  const response = await server().get(`/GetAllSuggestions`);
  return response.data;
};
