import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  addPermissionFailure,
  addPermissionSuccess,
  deletePermissionFailure,
  deletePermissionSuccess,
  getPermissionFailure,
  getPermissionSuccess,
  getPermissionsFailure,
  getPermissionsSuccess,
  updatePermissionFailure,
  updatePermissionSuccess,
} from "./actions";
import {
  ADD_PERMISSION,
  DELETE_PERMISSION,
  GET_PERMISSION,
  GET_PERMISSIONS,
  UPDATE_PERMISSION,
} from "./actionTypes";
import {
  addPermissionApi,
  deletePermissionApi,
  getPermissionApi,
  getPermissionsApi,
  updatePermissionApi,
} from "api/permissions";

function* getPermissionsSaga({ payload }) {
  try {
    const { responseData } = yield call(getPermissionsApi, payload);
    yield put(getPermissionsSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getPermissionsFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

function* getPermissionSaga({ payload }) {
  try {
    const { responseData } = yield call(getPermissionApi, payload);
    yield put(getPermissionSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getPermissionFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

function* addPermissionSaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(addPermissionApi, payload);
    yield put(addPermissionSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success("تمت الاضافة بنجاح");

      setTimeout(() => {
        payload?.navigate?.("/permissions");
      }, 1000);
    }
  } catch (error) {
    console.log(error);
    yield put(
      addPermissionFailure(error.response?.data?.responseData?.message)
    );
    yield payload?.toast?.error(error.response?.data?.responseData?.message);
  }
}

// ============================================================
// ============================================================

function* updatePermissionSaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(
      updatePermissionApi,
      payload
    );
    yield put(updatePermissionSuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success("تم التعديل بنجاح");

      setTimeout(() => {
        payload?.navigate?.("/permissions");
      }, 1000);
    }
  } catch (error) {
    console.log(error);
    yield put(
      updatePermissionFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

function* deletePermissionSaga({ payload }) {
  try {
    yield call(deletePermissionApi, payload);
    yield put(deletePermissionSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(
      deletePermissionFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

export function* watchGetPermissions() {
  yield takeEvery(GET_PERMISSIONS, getPermissionsSaga);
}

// ============================================================

export function* watchPermission() {
  yield takeEvery(GET_PERMISSION, getPermissionSaga);
}

// ============================================================

export function* watchAddPermission() {
  yield takeLatest(ADD_PERMISSION, addPermissionSaga);
}

// ============================================================

export function* watchUpdatePermission() {
  yield takeLatest(UPDATE_PERMISSION, updatePermissionSaga);
}

// ============================================================

export function* watchDeletePermission() {
  yield takeLatest(DELETE_PERMISSION, deletePermissionSaga);
}

// ============================================================
// ============================================================

function* permissionsSaga() {
  yield all([fork(watchGetPermissions)]);
  yield all([fork(watchPermission)]);
  yield all([fork(watchAddPermission)]);
  yield all([fork(watchUpdatePermission)]);
  yield all([fork(watchDeletePermission)]);
}

export default permissionsSaga;
