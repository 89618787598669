import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addAdmin, getRoles } from "store/actions";
import toast from "react-hot-toast";
import showPasswordIcon from "../../assets/svgs/eye.svg";

const AddAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { roles } = useSelector((state) => state.roles);

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(
      getRoles({
        pageNumber: 1,
        pageSize: 1000,
      })
    );
  }, [dispatch]);

  const onSubmit = (data) => {
    data.cmsUserRoles = selectedRoles?.map((role) => {
      return { cmsRoleId: role.id };
    });

    dispatch(
      addAdmin({
        data,
        toast,
        navigate,
      })
    );
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضافة مدير جديد</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المدير</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم المدير"
                          {...register("fullname", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" &&
                          "يرجي ادخال اسم المدير"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>البريد الالكتروني</h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder="البريد الالكتروني"
                          {...register("email", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.email?.type === "required" &&
                          "يرجي ادخال البريد الالكتروني"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رقم الهانف</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رقم الهاتف"
                          {...register("phone", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" &&
                          "يرجي ادخال رقم الهاتف"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>كلمة المرور</h5>
                      <div>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control form-outline"
                          placeholder="كلمة المرور"
                          {...register("password", { required: true })}
                        />
                        <button
                          type="button"
                          className={
                            showPassword
                              ? "show-password-btn active"
                              : "show-password-btn"
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img src={showPasswordIcon} alt="show password" />
                        </button>
                      </div>
                      <p className="error-hint">
                        {errors?.password && "يرجي ادخال كلمة المرور"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="permissions">
                      <div className="card-head">
                        <h4>الأدوار</h4>
                      </div>
                      <div className="card-body">
                        {roles?.map((role, index) => (
                          <label key={index}>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedRoles([...selectedRoles, role]);
                                } else {
                                  setSelectedRoles(
                                    selectedRoles?.filter(
                                      (selectedRole) =>
                                        selectedRole.id !== role.id
                                    )
                                  );
                                }
                              }}
                            />
                            <span>{role?.name}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
