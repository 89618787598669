import {
  DELETE_PERMISSION,
  DELETE_PERMISSION_FAILURE,
  DELETE_PERMISSION_SUCCESS,
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSION,
  GET_PERMISSION_FAILURE,
  GET_PERMISSION_SUCCESS,
  UPDATE_PERMISSION,
  UPDATE_PERMISSION_FAILURE,
  UPDATE_PERMISSION_SUCCESS,
  ADD_PERMISSION,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILURE,
} from "./actionTypes";

export const getPermissions = (payload) => {
  return {
    type: GET_PERMISSIONS,
    payload,
  };
};

export const getPermissionsSuccess = (payload) => {
  return {
    type: GET_PERMISSIONS_SUCCESS,
    payload,
  };
};

export const getPermissionsFailure = (payload) => {
  return {
    type: GET_PERMISSIONS_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const getPermission = (payload) => {
  return {
    type: GET_PERMISSION,
    payload,
  };
};

export const getPermissionSuccess = (payload) => {
  return {
    type: GET_PERMISSION_SUCCESS,
    payload,
  };
};

export const getPermissionFailure = (payload) => {
  return {
    type: GET_PERMISSION_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const addPermission = (payload) => {
  return {
    type: ADD_PERMISSION,
    payload,
  };
};

export const addPermissionSuccess = (payload) => {
  return {
    type: ADD_PERMISSION_SUCCESS,
    payload,
  };
};

export const addPermissionFailure = (payload) => {
  return {
    type: ADD_PERMISSION_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const updatePermission = (payload) => {
  return {
    type: UPDATE_PERMISSION,
    payload,
  };
};

export const updatePermissionSuccess = (payload) => {
  return {
    type: UPDATE_PERMISSION_SUCCESS,
    payload,
  };
};

export const updatePermissionFailure = (payload) => {
  return {
    type: UPDATE_PERMISSION_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================

export const deletePermission = (payload) => {
  return {
    type: DELETE_PERMISSION,
    payload,
  };
};

export const deletePermissionSuccess = (payload) => {
  return {
    type: DELETE_PERMISSION_SUCCESS,
    payload,
  };
};

export const deletePermissionFailure = (payload) => {
  return {
    type: DELETE_PERMISSION_FAILURE,
    payload,
  };
};

// ============================================================
// ============================================================
