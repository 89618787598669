import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdmin, updateAdmin, getRoles } from "store/actions";
import toast from "react-hot-toast";
import Loader from "components/shared/Loader";
import showPasswordIcon from "../../assets/svgs/eye.svg";

const EditAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { admin, loading } = useSelector((state) => state.admins);
  const { roles } = useSelector((state) => state.roles);

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(getAdmin({ id }));
    dispatch(
      getRoles({
        pageNumber: 1,
        pageSize: 1000,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      fullName: admin?.fullName,
      email: admin?.email,
      password: admin?.password,
      phone: admin?.phone,
    });

    setSelectedRoles(admin?.roles?.map((role) => role?.roleId));
  }, [admin, reset]);

  const handleCheckboxChange = (roleId) => {
    setSelectedRoles((prevRoles) => {
      if (prevRoles?.includes(roleId)) {
        return prevRoles?.filter((id) => id !== roleId);
      } else {
        return [...prevRoles, roleId];
      }
    });
  };

  const onSubmit = (data) => {
    data.userId = id;
    data.cmsUserRoles = selectedRoles?.map((role) => {
      return { cmsRoleId: role };
    });

    dispatch(
      updateAdmin({
        data,
        navigate,
        toast,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل البيانات</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم المدير</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم المدير"
                          {...register("fullName", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.fullName && "يرجي ادخال اسم المدير"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>البريد الالكتروني</h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder="البريد الالكتروني"
                          {...register("email", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.email && "يرجي ادخال البريد الالكتروني"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>رقم الهانف</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="رقم الهاتف"
                          {...register("phone", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.phone && "يرجي ادخال رقم الهاتف"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>كلمة المرور</h5>
                      <div>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control form-outline"
                          placeholder="كلمة المرور"
                          {...register("password", { required: true })}
                        />
                        <button
                          type="button"
                          className={
                            showPassword
                              ? "show-password-btn active"
                              : "show-password-btn"
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img src={showPasswordIcon} alt="show password" />
                        </button>
                      </div>
                      <p className="error-hint">
                        {errors?.password && "يرجي ادخال كلمة المرور"}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="permissions">
                      <div className="card-head">
                        <h4>الأدوار</h4>
                      </div>
                      <div className="card-body">
                        {roles?.map((role, index) => (
                          <label key={index}>
                            <input
                              type="checkbox"
                              checked={selectedRoles?.includes(role?.id)}
                              onChange={() => handleCheckboxChange(role?.id)}
                            />
                            <span>{role?.name}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
