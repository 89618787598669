import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addRole, getPermissions } from "store/actions";
import toast from "react-hot-toast";
const AddAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => state.permissions);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    dispatch(
      getPermissions({
        pageNumber: 1,
        pageSize: 1000,
      })
    );
  }, [dispatch]);

  const onSubmit = (data) => {
    data.permissionId = selectedPermissions.map((permission) => permission.id);

    dispatch(
      addRole({
        data,
        toast,
        navigate,
      })
    );
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضافة دور جديد</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>الاسم</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="الاسم"
                          {...register("roleName", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.roleName && "يرجي ادخال الاسم "}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="permissions">
                      <div className="card-head">
                        <h4>الصلاحيات</h4>
                      </div>
                      <div className="card-body">
                        {permissions?.map((permission, index) => (
                          <label key={index}>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedPermissions([
                                    ...selectedPermissions,
                                    permission,
                                  ]);
                                } else {
                                  setSelectedPermissions(
                                    selectedPermissions.filter(
                                      (selectedPermission) =>
                                        selectedPermission.id !== permission.id
                                    )
                                  );
                                }
                              }}
                            />
                            <span>{permission?.name}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
