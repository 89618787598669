import ControlArea from "components/Layout/ControlArea";
import React, { useEffect } from "react";
import Table from "./Table";
import blogIcon from "assets/svgs/blog.svg";
import { useDispatch } from "react-redux";
import { getBlogs } from "store/blogs/actions";
const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlogs());
  }, [dispatch]);

  return (
    <>
      <div className="users-wrap">
        <ControlArea
          btnTxt="اضافة مدونة"
          cardTxt="المدونات"
          icon={blogIcon}
          url="/blogs/add"
        />
        <Table />
      </div>
    </>
  );
};

export default Index;
