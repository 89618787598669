export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAILURE = "GET_ALL_BLOGS_FAILURE";

//===========================================================
//===========================================================

export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_FAILURE = "GET_SINGLE_BLOG_FAILURE";

//===========================================================
//===========================================================

export const ADD_BLOG = "ADD_BLOG";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";
export const ADD_BLOG_FAILURE = "ADD_BLOG_FAILURE";

//===========================================================
//===========================================================

export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAILURE = "UPDATE_BLOG_FAILURE";

//===========================================================
//===========================================================

export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAILURE = "DELETE_BLOG_FAILURE";

//===========================================================
//===========================================================
