import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import SearchIcon from "../../assets/svgs/search.svg";
import RemoveIcon from "../../assets/svgs/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserCollaborators, removeAssignedUser } from "store/actions";
import toast from "react-hot-toast";
import Loader from "components/shared/Loader";

const UserCollabartors = ({ show, setShow, selectedUser, page, pageSize }) => {
  const dispatch = useDispatch();

  const { userCollabartors, loading } = useSelector(
    (state) => state.authentication
  );

  const [searchValue, setSearchValue] = useState("");
  const [filteredCollabartors, setFilteredCollabartors] = useState([]);

  const resetInputs = () => {
    setSearchValue("");
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filtered = userCollabartors?.filter((collabartor) => {
      return (
        collabartor?.firstName?.toLowerCase().includes(e.target.value) ||
        collabartor?.lastName?.toLowerCase().includes(e.target.value) ||
        collabartor?.email?.toLowerCase().includes(e.target.value) ||
        collabartor?.phone?.toLowerCase().includes(e.target.value)
      );
    });
    setFilteredCollabartors(filtered);

    if (e.target.value === "") {
      setFilteredCollabartors(userCollabartors);
    }
  };

  useEffect(() => {
    dispatch(
      getUserCollaborators({
        ParentId: selectedUser?.id,
      })
    );
  }, [dispatch, selectedUser]);

  useEffect(() => {
    setFilteredCollabartors(userCollabartors);
  }, [userCollabartors]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      dialogClassName="modal-verify userCollabartors"
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          قائمة المشاركين
          <p>
            عرض المشاركين للمستخدم {selectedUser?.firstName}{" "}
            {selectedUser?.lastName}
          </p>
        </Modal.Title>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="بحث"
            value={searchValue}
            onChange={handleSearch}
          />
          <img src={SearchIcon} alt="search" />
        </div>
      </Modal.Header>
      <div className="collabartors-table">
        {loading ? (
          <Loader />
        ) : (
          <Table responsive bordered>
            <thead>
              <tr>
                <th>الاسم</th>
                <th>البريد الإلكتروني</th>
                <th>رقم الهاتف</th>
                <th>الحالة</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredCollabartors?.length > 0 ? (
                filteredCollabartors?.map((collabartor, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {collabartor?.firstName} {collabartor?.lastName}
                      </td>
                      <td>{collabartor?.email}</td>
                      <td>{collabartor?.phone}</td>
                      <td>{collabartor?.isVerified ? "مفعل" : "غير مفعل"}</td>
                      <td>
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>إزالة الاسناد</Tooltip>
                          }
                        >
                          <button
                            className="delete"
                            onClick={() => {
                              dispatch(
                                removeAssignedUser({
                                  data: {
                                    collaboratorId: collabartor?.id,
                                  },
                                  toast,
                                  dispatch,
                                  page,
                                  pageSize,
                                  resetInputs,
                                  selectedUser,
                                })
                              );
                            }}
                          >
                            <img src={RemoveIcon} alt="delete" />
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">
                    <div className="alert alert-warning text-center mb-0">
                      لا يوجد مشاركين
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>
    </Modal>
  );
};

export default UserCollabartors;
