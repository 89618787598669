import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

import "flatpickr/dist/themes/airbnb.css";

import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import deleteIcon from "../../assets/svgs/close.svg";
import axios from "axios";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { assignUser, removeAssignedUser } from "store/actions";

const AssignUser = ({ show, setShow, selectedUser, page, pageSize }) => {
  const dispatch = useDispatch();

  const { users, metadata } = useSelector((state) => state.authentication);

  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [usersPage, setUsersPage] = useState(1);
  const [usersMetadata, setUsersMetadata] = useState({});
  const [assignedUser, setAssignedUser] = useState({});

  const handleSearch = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/CmsManagment/GetAllUsers?SearchValue=${searchValue}&pageSize=10&pageNumber=${usersPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setSearchResults(res?.data?.responseData?.items);
        setUsersMetadata({
          pageIndex: res?.data?.responseData?.pageIndex,
          count: res?.data?.responseData?.count,
          pageSize: res?.data?.responseData?.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetInputs = () => {
    setSearchValue("");
    setAssignedUser({});
    setShow(false);
  };

  useEffect(() => {
    setSearchResults(users);
    setUsersPage(metadata?.pageIndex);
    setUsersMetadata({
      count: metadata?.count,
      pageSize: metadata?.pageSize,
    });
  }, [users, metadata]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      dialogClassName="modal-verify "
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          إسناد {selectedUser?.firstName} {selectedUser?.lastName} لمستخدم
        </Modal.Title>
      </Modal.Header>
      <div className="verify-body assign-company">
        <Row>
          {selectedUser?.parentData?.name && (
            <Col lg={12}>
              <div className="form-group">
                <h4>
                  <label>المسند اليه:</label>
                  <button
                    type="button"
                    onClick={() =>
                      dispatch(
                        removeAssignedUser({
                          data: {
                            collaboratorId: selectedUser?.id,
                          },
                          toast,
                          dispatch,
                          page,
                          pageSize,
                          resetInputs,
                        })
                      )
                    }
                  >
                    إلغاء الإسناد
                  </button>
                </h4>
                <div className="selected-company">
                  {selectedUser?.parentData?.name}
                </div>
              </div>
            </Col>
          )}
          <Col lg={12} md={12}>
            <div className="form-group">
              <label>بحث عن المستخدمين</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="بحث عن المستخدمين"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />

                {searchValue.length > 0 && (
                  <button
                    type="button"
                    onClick={() => {
                      setSearchValue("");
                      axios
                        .get(
                          `${process.env.REACT_APP_API_URL}/CmsManagment/GetAllUsers?SearchValue=&pageSize=10&pageNumber=1`,
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          }
                        )
                        .then((res) => {
                          setSearchResults(res?.data?.responseData?.items);
                          setUsersMetadata({
                            pageIndex: res?.data?.responseData?.pageIndex,
                            count: res?.data?.responseData?.count,
                            pageSize: res?.data?.responseData?.pageSize,
                          });
                          setUsersPage(1);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    <img src={deleteIcon} alt="delete" className="delete" />
                  </button>
                )}
              </div>
            </div>
          </Col>
          {searchResults?.length > 0 ? (
            <Col lg={12} md={12}>
              <div className="form-group">
                <label></label>
                <div className="companies-list">
                  {searchResults?.map(
                    (user, index) =>
                      user?.id !== selectedUser?.id && (
                        <label className="form-check" key={index}>
                          <input
                            type="radio"
                            name="user"
                            onChange={() => setAssignedUser(user)}
                            checked={assignedUser?.id === user?.id}
                          />
                          <span>{user?.email}</span>
                        </label>
                      )
                  )}
                </div>
              </div>
              <div className="table--pagination">
                <PaginationControl
                  page={usersPage}
                  between={3}
                  total={usersMetadata?.count || 0}
                  limit={usersMetadata?.pageSize || 10}
                  changePage={(page) => {
                    setUsersPage(page);

                    axios
                      .get(
                        `${process.env.REACT_APP_API_URL}/CmsManagment/GetAllUsers?SearchValue=${searchValue}&pageSize=10&pageNumber=${page}`,
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "token"
                            )}`,
                          },
                        }
                      )
                      .then((res) => {
                        setSearchResults(res?.data?.responseData?.items);
                        setUsersMetadata({
                          pageIndex: res?.data?.responseData?.pageIndex,
                          count: res?.data?.responseData?.count,
                          pageSize: res?.data?.responseData?.pageSize,
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  ellipsis={2}
                />
              </div>
            </Col>
          ) : (
            searchValue && (
              <Col lg={12}>
                <div className="form-group">
                  <h6 className="alert alert-warning text-center">
                    لا يوجد نتائج
                  </h6>
                </div>
              </Col>
            )
          )}
          <Col lg={12}>
            <div className="form-group has-btn">
              <button
                className="btn btn-blue"
                onClick={() => {
                  dispatch(
                    assignUser({
                      data: {
                        parentUserId: assignedUser?.id,
                        collaboratorId: selectedUser?.id,
                      },
                      toast,
                      dispatch,
                      page,
                      pageSize,
                      resetInputs,
                    })
                  );

                  setShow(false);
                }}
                disabled={!assignedUser?.id}
              >
                إسناد
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default AssignUser;
