import server from "./server";

export const postUserLogin = async (data) => {
  const response = await server().post("/CmsManagment/Login", data);
  return response.data;
};

export const getCurrentUser = async (data) => {
  const response = await server().get("/CmsManagment/CMSProfile", data);
  return response.data;
};

export const forgetPasswordApi = async ({ data }) => {
  const response = await server().post("/auth/ForgetPassword", data);
  return response.data;
};

export const getUsersApi = async ({ pageNumber, pageSize, SearchValue }) => {
  const response = await server().get(
    `/CmsManagment/GetAllUsers?SearchValue=${SearchValue}&PageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response.data;
};

export const verifyUserApi = async ({ data }) => {
  const response = await server().put("/CmsManagment/VerifyUser", data);
  return response.data;
};

export const deleteUserApi = async ({ id }) => {
  const response = await server().delete(`/CmsManagment/DeleteUserById/${id}`);
  return response.data;
};

export const updateUserApi = async ({ data }) => {
  const response = await server().put("/CmsManagment/UpdateUser", data);
  return response.data;
};

export const getUserApi = async ({ id }) => {
  const response = await server().get(`/CmsManagment/GetUserById/${id}`);
  return response.data;
};

export const assignCompanyApi = async ({ data }) => {
  const response = await server().put("/CmsManagment/UpdateUserCompany", data);
  return response.data;
};

export const removeAssignedCompanyApi = async ({ data }) => {
  const response = await server().put(
    "/CmsManagment/RemoveUserEdimateCompany",
    data
  );
  return response.data;
};

export const getUserAuditHistoryApi = async ({ id }) => {
  const response = await server().get(
    `/CmsManagment/GetUserAuditHistory?userId=${id}`
  );
  return response.data;
};

export const getUserControlBoardApi = async ({ id, companyId }) => {
  const response = await server().get(
    `/CmsManagment/GetUserControlBoard?userId=${id}&companyId=${companyId}`
  );
  return response.data;
};

export const getAllModulesApi = async () => {
  const response = await server().get(`/GetAllModules`);
  return response.data;
};

export const updateUserModulesApi = async ({ data }) => {
  const response = await server().put("/UpdateUserModule", data);
  return response.data;
};

export const addUserModuleApi = async ({ data }) => {
  const response = await server().post("/AddUserModule", data);
  return response.data;
};

export const getUserModulesApi = async ({ useerId }) => {
  const response = await server().get(
    `/CmsManagment/GetUserModulesByUserId?useerId=${useerId}`
  );
  return response.data;
};

export const getWinRatePerQuarterApi = async ({ useerId }) => {
  const response = await server().get(
    `/CmsManagment/GetWinRatePerQuarter?useerId=${useerId}`
  );
  return response.data;
};

export const assignUserApi = async ({ data }) => {
  const response = await server().post(
    "/Collaborator/AssignOrUnAssignCollaboratorToUser",
    data
  );
  return response.data;
};

export const removeAssignedUserApi = async ({ data }) => {
  const response = await server().post(
    "/Collaborator/AssignOrUnAssignCollaboratorToUser",
    data
  );
  return response.data;
};

export const getHealthStatusApi = async () => {
  const response = await server().get("/CmsManagment/GetTableStats");
  return response.data;
};

export const getUserCollaboratorsApi = async ({ ParentId }) => {
  const response = await server().get(
    `/Collaborator/GetParentWithCollaborators?ParentId=${ParentId}`
  );
  return response.data;
};
