export const GET_ALL_INQUIRIES = "GET_ALL_INQUIRIES";
export const GET_ALL_INQUIRIES_SUCCESS = "GET_ALL_INQUIRIES_SUCCESS";
export const GET_ALL_INQUIRIES_FAILURE = "GET_ALL_INQUIRIES_FAILURE";

//===========================================================
//===========================================================

export const GET_ALL_SUGGESTIONS = "GET_ALL_SUGGESTIONS";
export const GET_ALL_SUGGESTIONS_SUCCESS = "GET_ALL_SUGGESTIONS_SUCCESS";
export const GET_ALL_SUGGESTIONS_FAILURE = "GET_ALL_SUGGESTIONS_FAILURE";

//===========================================================
//===========================================================
