import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

import Loader from "components/shared/Loader";
import { Col, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

const WinRateTable = ({ data }) => {
  const [pending, setPending] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [changeLayout, setChangeLayout] = useState("جدول");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const filteredItems = data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const columns = [
    {
      name: "عدد المنافسات المشاركة",
      selector: (row) => row?.participantTenders,
      sortable: true,
    },
    {
      name: "عدد المنافسات الرابحة",
      selector: (row) => row?.winTenders,
      sortable: true,
    },
    {
      name: "السنة",
      selector: (row) => `20${row?.year}`,
      sortable: true,
      width: "160px",
    },
    {
      name: "الربع",
      selector: (row) =>
        row?.quarter === 1
          ? "الربع الأول"
          : row?.quarter === 2
          ? "الربع الثاني"
          : row?.quarter === 3
          ? "الربع الثالث"
          : "الربع الرابع",
      sortable: true,
      width: "130px",
    },
    {
      name: "نسبة الفوز",
      selector: (row) => `${row?.winRate} %`,
      sortable: true,
      width: "150px",
    },
  ];

  const quarters = filteredItems.map((item) => `Q${item.quarter} ${item.year}`);
  const participantTenders = filteredItems.map(
    (item) => item.participantTenders
  );
  const winTenders = filteredItems.map((item) => item.winTenders);
  const winRates = filteredItems.map((item) => item.winRate);

  const commonOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: quarters,
    },
  };

  const optionsBar = {
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const seriesBar = [
    {
      name: "عدد المنافسات الفائز بها",
      data: participantTenders,
      color: "#28384F",
    },
    {
      name: "عدد المنافسات المشارك بها",
      data: winTenders,
      color: "#0DACA3",
    },
  ];

  const optionsLine = {
    ...commonOptions,
    chart: {
      ...commonOptions.chart,
      type: "area",
      height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 200],
      },
    },
  };

  const seriesLine = [
    {
      name: "نسبة الفوز بالمنافسات",
      data: winRates,
      color: "#0DACA3",
    },
  ];

  return (
    <>
      <div className="table-filter">
        <Row>
          <Col lg={3} md={6} xs={12}>
            <div className="form-group">
              <select
                className="form-control form-select"
                onChange={(e) => setFilterText(e.target.value)}
              >
                <option value="">اختر السنة</option>
                <option value="20">2020</option>
                <option value="21">2021</option>
                <option value="22">2022</option>
                <option value="23">2023</option>
                <option value="24">2024</option>
              </select>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div className="form-group">
              <select
                className="form-control form-select"
                onChange={(e) => setChangeLayout(e.target.value)}
              >
                <option value="جدول">جدول</option>
                <option value="رسم بياني">رسم بياني</option>
              </select>
            </div>
          </Col>
        </Row>
      </div>
      {changeLayout === "جدول" ? (
        <DataTable
          columns={columns}
          data={filteredItems}
          progressPending={pending}
          progressComponent={<Loader />}
          noDataComponent={
            <div className="alert alert-warning text-center w-100">
              لا توجد بيانات لعرضها
            </div>
          }
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15]}
          paginationComponentOptions={{
            rowsPerPageText: "الصفوف في الصفحة",
            rangeSeparatorText: "من",
            selectAllRowsItem: true,
            selectAllRowsItemText: "الكل",
          }}
          defaultSortField="name"
        />
      ) : (
        <Row>
          <Col lg={6} md={12}>
            <ReactApexChart
              options={optionsBar}
              series={seriesBar}
              type="bar"
              height={350}
            />
          </Col>
          <Col lg={6} md={12}>
            <ReactApexChart
              options={optionsLine}
              series={seriesLine}
              type="area"
              height={350}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default WinRateTable;
