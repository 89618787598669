import {
  addCategoryApi,
  deleteCategoryApi,
  getCategoriesApi,
  getSingleCategoryApi,
  updateCategoryApi,
} from "api/categories";
import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  addCategoryFailure,
  addCategorySuccess,
  deleteCategoryFailure,
  deleteCategorySuccess,
  getCategoriesFailure,
  getCategoriesSuccess,
  getSingleCategoryFailure,
  getSingleCategorySuccess,
  updateCategoryFailure,
  updateCategorySuccess,
} from "./actions";
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  GET_ALL_CATEGORIES,
  GET_SINGLE_CATEGORY,
  UPDATE_CATEGORY,
} from "./actionTypes";

function* getAllCategoriesSaga({ payload }) {
  try {
    const { responseData } = yield call(getCategoriesApi, payload);
    yield put(getCategoriesSuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getCategoriesFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

function* getSingleCategorySaga({ payload }) {
  try {
    const { responseData } = yield call(getSingleCategoryApi, payload);
    yield put(getSingleCategorySuccess(responseData));
  } catch (error) {
    console.log(error);
    yield put(
      getSingleCategoryFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

function* addCategorySaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(addCategoryApi, payload);
    yield put(addCategorySuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success("تمت الاضافة بنجاح");

      setTimeout(() => {
        payload?.navigate?.("/categories");
      }, 1000);
    }
  } catch (error) {
    console.log(error);
    yield put(addCategoryFailure(error.response?.data?.responseData?.message));
    yield payload?.toast?.error(error.response?.data?.responseData?.message);
  }
}

// ============================================================
// ============================================================

function* updateCategorySaga({ payload }) {
  try {
    const { responseData, isSuccess } = yield call(updateCategoryApi, payload);
    yield put(updateCategorySuccess(responseData));
    if (isSuccess) {
      yield payload?.toast?.success("تم التعديل بنجاح");

      setTimeout(() => {
        payload?.navigate?.("/categories");
      }, 1000);
    }
  } catch (error) {
    console.log(error);
    yield put(
      updateCategoryFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

function* deleteCategorySaga({ payload }) {
  try {
    yield call(deleteCategoryApi, payload);
    yield put(deleteCategorySuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(
      deleteCategoryFailure(error.response?.data?.responseData?.message)
    );
  }
}

// ============================================================
// ============================================================

export function* watchGetAllCategories() {
  yield takeEvery(GET_ALL_CATEGORIES, getAllCategoriesSaga);
}

// ============================================================

export function* watchGetSingleCategory() {
  yield takeEvery(GET_SINGLE_CATEGORY, getSingleCategorySaga);
}

// ============================================================

export function* watchAddCategory() {
  yield takeEvery(ADD_CATEGORY, addCategorySaga);
}

// ============================================================

export function* watchUpdateCategory() {
  yield takeEvery(UPDATE_CATEGORY, updateCategorySaga);
}

// ============================================================

export function* watchDeleteCategory() {
  yield takeEvery(DELETE_CATEGORY, deleteCategorySaga);
}

// ============================================================
// ============================================================

function* categoriesSaga() {
  yield all([fork(watchGetAllCategories)]);
  yield all([fork(watchGetSingleCategory)]);
  yield all([fork(watchAddCategory)]);
  yield all([fork(watchUpdateCategory)]);
  yield all([fork(watchDeleteCategory)]);
}

export default categoriesSaga;
