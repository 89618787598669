import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  ADD_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
} from "./actionTypes";

const initialState = {
  roles: [],
  role: {},
  metadata: {},
  isLoggedIn: null,
  loading: false,
  error: "",
};

const roles = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        loading: true,
      };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload?.items,
        metadata: {
          count: action.payload?.count,
          pageSize: action.payload?.pageSize,
          pageIndex: action.payload?.pageIndex,
        },
      };

    case GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case GET_ROLE:
      return {
        ...state,
        loading: true,
      };

    case GET_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload?.[0],
      };

    case GET_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case ADD_ROLE:
      return {
        ...state,
        loading: true,
      };

    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: [...state.roles, action.payload],
      };

    case ADD_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case UPDATE_ROLE:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: state.roles.map((role) =>
          role.id === action.payload.id ? action.payload : role
        ),
      };

    case UPDATE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    case DELETE_ROLE:
      return {
        ...state,
        loading: true,
      };

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: state.roles.filter((role) => role?.id !== action.payload?.id),
      };

    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // ============================================================
    // ============================================================

    default:
      return state;
  }
};

export default roles;
